// ==============================
// Override Variables
// ==============================

// ショートコードの文字色
//$code-color: #E74C3C !default;
//$code-color-dark: #E5BF78 !default;
//$code-color-dark: #e3e3e3;
//$code-color-dark: #333333;
//$code-color: $code-color-dark;

// ショートコードの背景色
//$code-background-color: #f5f5f5 !default;
//$code-background-color-dark: #272C34 !default;
//$code-background-color-dark: #364549;
//$code-background-color-dark: #eeeeee;
//$code-background-color-dark: #888888;
//$code-background-color: $code-background-color-dark;

$code-highlight-color-map-dark: (
  /* Parentheses */ 'p': #a9a9b3,
  /* Keyword */ 'k': #d371e3,
  /* KeywordConstant */ 'kc': #d371e3,
  /* KeywordDeclaration */ 'kd': #d371e3,
  /* KeywordNamespace */ 'kn': #d371e3,
  /* KeywordPseudo */ 'kp': #d371e3,
  /* KeywordReserved */ 'kr': #d371e3,
  /* KeywordType */ 'kt': #d371e3,
  /* Name */ 'n': #a9b2c0,
  /* NameAttribute */ 'na': #41b0f5,
  /* NameBuiltin */ 'nb': #19b9c4,
  /* NameBuiltinPseudo */ 'bp': #ecbf6f,
  /* NameClass */ 'nc': #ecbf6f,
  /* NameConstant */ 'no': #41b0f5,
  /* NameDecorator */ 'nd': #ecbf6f,
  /* NameEntity */ 'ni': #41b0f5,
  /* NameException */ 'ne': #41b0f5,
  /* NameFunction */ 'nf': #41b0f5,
  /* NameFunctionMagic */ 'fm': #19b9c4,
  /* NameLabel */ 'nl': #41b0f5,
  /* NameNamespace */ 'nn': #41b0f5,
  /* NameOther */ 'nx': #a9a9b3,
  /* NameProperty */ 'py': #41b0f5,
  /* NameTag */ 'nt': #41b0f5,
  /* NameVariable */ 'nv': #41b0f5,
  /* NameVariableClass */ 'vc': #41b0f5,
  /* NameVariableGlobal */ 'vg': #41b0f5,
  /* NameVariableInstance */ 'vi': #41b0f5,
  /* NameVariableMagic */ 'vm': #41b0f5,
  /* Literal */ 'l': #2aa198,
  /* LiteralDate */ 'ld': #2aa198,
  /* LiteralString */ 's': #8cc570,
  /* LiteralStringAffix */ 'sa': #8cc570,
  /* LiteralStringBacktick */ 'sb': #8cc570,
  /* LiteralStringChar */ 'sc': #8cc570,
  /* LiteralStringDelimiter */ 'dl': #8cc570,
  /* LiteralStringDoc */ 'sd': #8cc570,
  /* LiteralStringDouble */ 's2': #8cc570,
  /* LiteralStringEscape */ 'se': #8cc570,
  /* LiteralStringHeredoc */ 'sh': #8cc570,
  /* LiteralStringInterpol */ 'si': #8cc570,
  /* LiteralStringOther */ 'sx': #8cc570,
  /* LiteralStringRegex */ 'sr': #8cc570,
  /* LiteralStringSingle */ 's1': #8cc570,
  /* LiteralStringSymbol */ 'ss': #8cc570,
  /* LiteralNumber */ 'm': #db985c,
  /* LiteralNumberBin */ 'mb': #db985c,
  /* LiteralNumberFloat */ 'mf': #db985c,
  /* LiteralNumberHex */ 'mh': #db985c,
  /* LiteralNumberInteger */ 'mi': #db985c,
  /* LiteralNumberIntegerLong */ 'il': #db985c,
  /* LiteralNumberOct */ 'mo': #db985c,
  /* Operator */ 'o': #ecbf6f,
  /* OperatorWord */ 'ow': #d371e3,
  /* Comment */ 'c': #7e848f,
  /* CommentHashbang */ 'ch': #7e848f,
  /* CommentMultiline */ 'cm': #7e848f,
  /* CommentSingle */ 'c1': #7e848f,
  /* CommentSpecial */ 'cs': #7e848f,
  /* CommentPreproc */ 'cp': #7e848f,
  /* CommentPreprocFile */ 'cpf': #7e848f,
  /* Generic */ 'g': #f16372,
  /* GenericDeleted */ 'gd': #f16372,
  /* GenericEmph */ 'ge': #f16372,
  /* GenericError */ 'gr': #f16372,
  /* GenericHeading */ 'gh': #f16372,
  /* GenericInserted */ 'gi': #f16372,
  /* GenericOutput */ 'go': #f16372,
  /* GenericPrompt */ 'gp': #f16372,
  /* GenericStrong */ 'gs': #f16372,
  /* GenericSubheading */ 'gu': #f16372,
  /* GenericTraceback */ 'gt': #f16372,
  /* TextWhitespace */ 'w': #bbbbbb,
);

$code-highlight-color-map: $code-highlight-color-map-dark;

//$global-font-family: system-ui, -apple-system, BlinkMacSystemFont, PingFang SC, Microsoft YaHei UI, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif !default;
//$global-font-family: system-ui;
$global-font-family: system-ui, -apple-system, BlinkMacSystemFont, PingFang SC, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
